import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

// Three column single row grid, allows us to position login and header button
const Wrapper = styled.div`
display: grid;
height: 44px;
font-size: 1.0em;
font-family: Lucida Console, Courier, monospace;
margin-bottom: 1em;
grid-template-columns:  1fr 2fr 1fr;
`;

const Row = styled.div` 
  display: flex;
  padding-left: 0px;
  border-color: black;
  border-width: 10px;
  justify-content: left;
  align-items: center;
  user-select: none;
`;

const SimpleButtonWrapper = styled.div`
  background: white;
  width: 100%;
`;

const Header = ({ siteTitle }) => (
  <header>
    <Wrapper>
      <Row>
        <Link to={'/'}>
          <span> {siteTitle} </span>
        </Link>
      </Row>
    </Wrapper>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
