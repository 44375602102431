/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */
import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import styled from 'styled-components'

import Header from "./header"
import "./layout.css"


const Main = styled.div`
  padding-left: 10%;
  padding-right: 10%;
`;

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <link rel="shortcut icon" href="favicon-64.png" type="image/x-icon" />
        <div
          style={{
            margin: `0 auto`,
            padding: `0px 0px 0px`,
            paddingTop: 0,
          }}>
          <Main>
            <Header siteTitle={data.site.siteMetadata.title} />
            {children}
          </Main>
          <footer>
          </footer>
        </div>
      </>
    )}
  />
)
Layout.propTypes = {
  children: PropTypes.node.isRequired,
}
export default Layout
